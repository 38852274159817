import styled from "styled-components";

interface IProps {
  backgroundColor?: string;
}

const BreadcrumbsWrapper = styled.div<IProps>`
  ol,
  nav,
  li,
  a,
  div {
    font-family: ${(props) => props.theme.fontFamily.OpenSans};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    background-color: ${(props) => props.backgroundColor || "white"};
    color: ${(props) => props.theme.colors.Dark};
    white-space: nowrap;
    margin: 0;
    padding: 0;
    display: flex;
  }

  margin: 0 0 14px 0;
`;

export { BreadcrumbsWrapper };
