import React from "react";
import { RegisterDealershipOrganism } from "../../organisms";

export const RegisterDealershipPage: React.FC = () => {
  return (
    <>
      <RegisterDealershipOrganism />
    </>
  );
};
