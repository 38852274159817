import { NumberHelper } from "c4u-web-components";
import React from "react";
import { ISizeProp } from "../../../models";
import {
  BalanceContent,
  BalanceLabel,
  BalanceCardWrapper,
} from "./balance-summary.atom.style";

interface IProps {
  label: string;
  value?: number;
  size?: ISizeProp;
}

export const BalanceSummaryAtom: React.FC<IProps> = ({ label, value, size }) => {
  const labelSplit = label.split(' ')
  return (
    <BalanceCardWrapper>
      <BalanceLabel size={size}>{labelSplit[0]}<span>{labelSplit[1]}</span></BalanceLabel>
      <BalanceContent size={size}>
        { value !== undefined ? NumberHelper.toFormatString(value) : '-'}
      </BalanceContent>
    </BalanceCardWrapper>
  );
};
