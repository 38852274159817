import styled from "styled-components";

export const CreditAndOrdersWrapper = styled.div`
  display: flex;
  gap: 16px;

  a {
    color: ${({ theme }) => theme.colors.Auth};
    font-weight: 700;
    font-family: ${({ theme }) => theme.fontFamily.OpenSans};
    font-size: 12px;
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-transform: uppercase;
    display: flex;
    gap: 4px;
    align-items: center;
  }
`

export const Divider = styled.hr`
  height: 22px;
  width: 1px;
  border-left: 1px solid #E1E1E2;
  margin: 0;
`
