import { ButtonPrimary } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../../constants";
import { useRenave, useSessionContext } from "../../../../hooks";
import {
  ICreditAcquisitionForm,
  IFormikSetErrors,
  PostPacksRequest,
} from "../../../../models";
import { CreditAcquisitionFormMolecule } from "../../../molecules";
import {
  BoxPayNow,
  WrapperCreditAcquisition,
} from "./credit-acquisition-payment.organism.style";

interface IProps {
  packId: number;
}

export const CreditAcquisitionPaymentOrganism: React.FC<IProps> = ({
  packId,
}) => {
  const { t } = useTranslation();
  const { postPacks } = useRenave();
  const {
    handleFormikException,
    showGenericSuccessModal,
    showGenericErrorModal,
  } = useSessionContext();

  const [goSubmit, setGoSubmit] = useState(false);
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displaysPayButton, setDisplaysPayButton] = useState(false);

  const handleSubmitCreditPayment = useCallback(
    async (
      values: ICreditAcquisitionForm,
      { setErrors }: IFormikSetErrors<ICreditAcquisitionForm>
    ) => {
      try {
        const data = await postPacks(
          new PostPacksRequest({
            packId: packId,
            ...values,
          })
        );
        data.result
          ? showGenericSuccessModal(
              " ",
              () => history.push(paths.home()),
              t("PaymentAccept")
            )
          : showGenericErrorModal(" ", null, t("PaymentFailed"));
      } catch (e) {
        handleFormikException(e, setErrors);
      }
    },
    [
      handleFormikException,
      history,
      packId,
      postPacks,
      showGenericErrorModal,
      showGenericSuccessModal,
      t,
    ]
  );

  useEffect(() => {
    if (goSubmit) setGoSubmit(false);
  }, [goSubmit]);

  return (
    <WrapperCreditAcquisition>
      <CreditAcquisitionFormMolecule
        goSubmit={goSubmit}
        onSubmit={handleSubmitCreditPayment}
        setIsSubmitting={setIsSubmitting}
        displaysPayButton={displaysPayButton}
        setDisplaysPayButton={setDisplaysPayButton}
        packId={packId}
      />

      {displaysPayButton && (
        <BoxPayNow>
          <ButtonPrimary
            sizex="md"
            loading={isSubmitting}
            onClick={() => {
              setGoSubmit(true);
            }}
          >
            {t("PayNow")}
          </ButtonPrimary>
        </BoxPayNow>
      )}
    </WrapperCreditAcquisition>
  );
};
