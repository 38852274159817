import { ButtonTertiary } from "c4u-web-components";
import styled from "styled-components";

export const BalanceAndCreditsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  margin: 22px 0px 16px 0px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

export const EntryVehicleButton = styled(ButtonTertiary)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  height: 40px;
  width: 264px;
  padding: 0px 24px;
  margin: unset;

  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  text-transform: uppercase;

  @media (max-width: 991px) {
    width: 70px;
  }

  g {
    path:nth-child(3){
      stroke: ${({ theme }) => theme.colors.BlueNavy};
    }
  }
`
