import { SpinnerCenteredAtom } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { useRenaveContext } from "../../../hooks";
import { RenaveInformationMolecule } from "../../molecules";
import { HeaderTitleMolecule } from "../../molecules";

export const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const { userContext } = useRenaveContext();
  const history = useHistory();
  const [continueOnPage, setcontinueOnPage] = useState(false);

  useEffect(() => {
    if (userContext !== undefined) {
      userContext?.user.isMaster
        ? history.push(paths.registerDealership())
        : setcontinueOnPage(true);
    }
  }, [history, userContext]);

  return (
    <>
      {continueOnPage && userContext !== undefined ? (
        <>
          <HeaderTitleMolecule title={t("Renave")} hideReturnButton />
          <hr className="mt-5" />
          <RenaveInformationMolecule />
        </>
      ) : (
          <div style={{ height: '80vh' }}>
            <SpinnerCenteredAtom />
          </div>
      )}
    </>
  );
};
