import styled from "styled-components";
import { ISizeProp } from "../../../models";

interface BalanceProps {
  size?: ISizeProp;
}

export const BalanceContent = styled.div<BalanceProps>`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;

  font-size: ${({ size }) =>
    size === "sm"
      ? "14px"
      : size === "md"
      ? "18px"
      : size === "lg"
      ? "22px"
      : "22px"};

 line-height: ${({ size }) =>
   size === "sm"
     ? "20px"
     : size === "md"
     ? "24px"
     : size === "lg"
     ? "29px"
     : "29px"};

  white-space: nowrap;
  color: ${(props) => props.theme.colors.Dark};
`;

export const BalanceLabel = styled.div<BalanceProps>`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  flex-direction: column;

  span {
    font-weight: 700;
    font-size: ${({ size }) =>
      size === "sm"
        ? "10px"
        : size === "md"
        ? "12px"
        : size === "lg"
        ? "14px"
        : "14px"};

    line-height: ${({ size }) =>
      size === "sm"
        ? "16px"
        : size === "md"
        ? "18px"
        : size === "lg"
        ? "21px"
        : "21px"};
  }

  font-size: ${({ size }) =>
    size === "sm"
      ? "10px"
      : size === "md"
      ? "12px"
      : size === "lg"
      ? "14px"
      : "14px"};

  line-height: ${({ size }) =>
    size === "sm"
      ? "8px"
      : size === "md"
      ? "10px"
      : size === "lg"
      ? "12px"
      : "12px"};

  text-align: center;
  color: ${(props) => props.theme.colors.grey80};
`;

export const BalanceCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 64px;
  max-width: 85px;
`;
