import {
  FormikControlAtom,
  MaskHelper,
  VehicleCategoriesEnum,
  SpinnerCenteredAtom,
  Combo,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect, useMemo } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StaticVehicleOptions, VehicleOptions } from "..";
import { VehicleHelper } from "../../../helper";
import { useRenaveContext } from "../../../hooks";
import { ICheckInZeroKmModel } from "../../../models";
import { FormikControlCalendarAtom } from "../../atoms";
import {
  SelectVehicleContentLoader,
  SelectVehicleDescription,
  SelectVehicleDiv,
  SelectVehicleName,
} from "../shared-style/molecules.shared-style";
import {
  MainWrapperVehicleDataForm,
  StyledFormRow,
} from "./zero-km-vehicle-data-form.molecule.style";
import { ZeroKmVehicleDataFormValidation } from "./zero-km-vehicle-data-form.molecule.validation";

interface IProps {
  setIsSubmitting?: (v: boolean) => void;
  goSubmit?: boolean;
  onSubmit?: (values: any, errors: any) => Promise<void>;
  initialValues?: ICheckInZeroKmModel;
  disableAll?: boolean;
  vehicleCategory: VehicleCategoriesEnum;
  setVehicleCategory: (vehicleCategory: VehicleCategoriesEnum) => void;
  setHasSelectedCategory?: (value: boolean) => void;
}

export const ZeroKmVehicleDataFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const {
    kbbVehicleContext,
    zeroKmVehicleEntryContext,
    vehicleCategoryContext,
    sourcePartnerContext,
    setSourcePartnerContext,
  } = useRenaveContext();

  const isCarCategory = useMemo(
    () => vehicleCategoryContext === VehicleCategoriesEnum.Car,
    [vehicleCategoryContext]
  );

  const formik = useFormik<ICheckInZeroKmModel>({
    initialValues: props.initialValues ?? ({} as ICheckInZeroKmModel),
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      if (props.onSubmit) {
        await props.onSubmit(values, { setErrors });
        setSubmitting(false);
      }
    },
    validationSchema: ZeroKmVehicleDataFormValidation(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    if (kbbVehicleContext?.id)
      formik.setFieldValue("kbbId", kbbVehicleContext.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kbbVehicleContext?.id]);

  useEffect(() => {
    if (kbbVehicleContext?.molicarID)
      formik.setFieldValue("molicarID", kbbVehicleContext.molicarID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kbbVehicleContext?.molicarID]);

  useEffect(() => {
    if (kbbVehicleContext?.brandName)
      formik.setFieldValue("brand", kbbVehicleContext.brandName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kbbVehicleContext?.brandName]);

  useEffect(() => {
    if (kbbVehicleContext?.year)
      formik.setFieldValue("modelYear", kbbVehicleContext.year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kbbVehicleContext?.year]);

  useEffect(() => {
    if (kbbVehicleContext?.modelName)
      formik.setFieldValue("model", kbbVehicleContext.modelName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kbbVehicleContext?.modelName]);

  useEffect(() => {
    if (kbbVehicleContext?.versionName) {
      formik.setFieldValue("version", kbbVehicleContext.versionName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kbbVehicleContext?.versionName]);

  useEffect(() => {
    if (props.goSubmit) {
      formik.submitForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.goSubmit]);

  useEffect(() => {
    if (props.setIsSubmitting) {
      props.setIsSubmitting(formik.isSubmitting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isSubmitting]);

  const yearsListManufacture = useMemo(() => {
    const currentYear = new Date().getFullYear();

    if (!kbbVehicleContext) return [];

    if (kbbVehicleContext?.year > currentYear) {
      return [
        new Combo({
          title: kbbVehicleContext.year - 1,
          value: kbbVehicleContext.year - 1,
        }),
      ];
    } else {
      return [
        new Combo({
          title: kbbVehicleContext.year - 1,
          value: kbbVehicleContext.year - 1,
        }),
        new Combo({
          title: kbbVehicleContext.year,
          value: kbbVehicleContext.year,
        }),
      ];
    }
  }, [kbbVehicleContext]);

  const sizeProps1inLine = {
    lg: 10,
    xl: 8,
  };
  const sizeProps2inLine = {
    md: 6,
    lg: 5,
    xl: 4,
  };

  return (
    <MainWrapperVehicleDataForm>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Form.Row>
          <FormikControlAtom
            label={t("Chassi")}
            property={"vin"}
            formik={formik}
            translate={t}
            disabled
            {...sizeProps1inLine}
          />
        </Form.Row>

        <SelectVehicleDiv>
          {props.disableAll ? (
            <>
              {kbbVehicleContext?.brandName ? (
                <SelectVehicleName>
                  {VehicleHelper.formatName(
                    kbbVehicleContext?.brandName,
                    kbbVehicleContext?.modelName,
                    kbbVehicleContext?.year,
                    kbbVehicleContext?.versionName
                  )}
                </SelectVehicleName>
              ) : (
                <SelectVehicleContentLoader>
                  <SpinnerCenteredAtom />
                  <SelectVehicleDescription>
                    {t("LoadingVehicleData")}
                  </SelectVehicleDescription>
                </SelectVehicleContentLoader>
              )}
            </>
          ) : (
            kbbVehicleContext?.brandName && (
              <SelectVehicleName>
                {VehicleHelper.formatName(
                  kbbVehicleContext.brandName,
                  kbbVehicleContext.modelName,
                  kbbVehicleContext.year,
                  kbbVehicleContext.versionName
                )}
              </SelectVehicleName>
            )
          )}
        </SelectVehicleDiv>

        <StyledFormRow>
          <Col {...sizeProps1inLine}>
            {(props.disableAll && zeroKmVehicleEntryContext?.kbbId) ||
            zeroKmVehicleEntryContext?.molicarID ? (
              <StaticVehicleOptions
                vehicleKbbId={
                  isCarCategory
                    ? zeroKmVehicleEntryContext?.kbbId.toString()
                    : zeroKmVehicleEntryContext?.molicarID
                }
                isZeroKm
                sourcePartner={sourcePartnerContext}
                setSourcePartner={setSourcePartnerContext}
                setHasSelectedCategory={(value) =>
                  props.setHasSelectedCategory &&
                  props.setHasSelectedCategory(value)
                }
              />
            ) : (
              <VehicleOptions
                vehicleKbb={
                  kbbVehicleContext
                    ? {
                        idBrand: kbbVehicleContext.brandID,
                        id: kbbVehicleContext.id,
                        idModel: kbbVehicleContext.modelID,
                        year: kbbVehicleContext.year,
                        molicarID: kbbVehicleContext.molicarID,
                      }
                    : undefined
                }
                isZeroKm
                setHasSelectedCategory={(value) =>
                  props.setHasSelectedCategory &&
                  props.setHasSelectedCategory(value)
                }
              />
            )}
          </Col>
        </StyledFormRow>

        {kbbVehicleContext?.versionID && (
          <>
            <Form.Row>
              <FormikControlAtom
                label={t("EntryType")}
                property={"entryType"}
                formik={formik}
                translate={t}
                {...sizeProps1inLine}
                disabled
              />
            </Form.Row>

            <Form.Row>
              <FormikControlAtom
                type={"select"}
                data={yearsListManufacture}
                label={t("ManufacturingYear")}
                property={"manufactureYear"}
                formik={formik}
                translate={t}
                {...sizeProps2inLine}
                disabled={props.disableAll}
              />
              <FormikControlCalendarAtom
                label={t("EntryStockDate")}
                property="stockEntryDate"
                formik={formik}
                disabled={props.disableAll}
              />
              <FormikControlAtom
                type={"money"}
                label={t("ValuePurchase")}
                property="valuePurchase"
                formik={formik}
                translate={t}
                placeholder="R$"
                disabled={props.disableAll}
                {...sizeProps2inLine}
              />
            </Form.Row>

            <Form.Row>
              <FormikControlAtom
                label={t("odometerMileage")}
                property={"odometerMileage"}
                formik={formik}
                translate={t}
                type="only-numbers"
                maxLength={8}
                thousandSeparator
                placeholderChar={"\u2000"}
                disabled={props.disableAll}
              />
              <FormikControlCalendarAtom
                label={t("MileageMeasureDate")}
                property="measurementOdometerDate"
                formik={formik}
                disabled={props.disableAll}
              />
              <FormikControlAtom
                label={t("MileageMeasureHour")}
                property={"measurementOdometerHour"}
                formik={formik}
                translate={t}
                mask={[/[0-2]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
                xs={4}
                disabled={props.disableAll}
              />
            </Form.Row>

            <Form.Row>
              <FormikControlAtom
                label={t("invoiceKey")}
                property={"invoiceKey"}
                formik={formik}
                translate={t}
                type="only-numbers"
                maxLength={MaskHelper.DANFE_NUMBER_LENGTH}
                placeholderChar={"\u2000"}
                disabled={props.disableAll}
              />
            </Form.Row>
          </>
        )}
      </Form>
    </MainWrapperVehicleDataForm>
  );
};
