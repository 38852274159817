import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import { BreadcrumbsWrapper } from "./breadcrumbs.atom.style";
import { IBreadcrumbs } from "../../../models";

interface IProps {
  breadcrumbs: IBreadcrumbs[];
  backgroundColor?: string;
}

export const BreadcrumbsAtom: React.FC<IProps> = ({
  breadcrumbs,
  backgroundColor,
}) => {
  return (
    <BreadcrumbsWrapper backgroundColor={backgroundColor}>
      <Breadcrumb>
        {breadcrumbs.map((breadcrumb, i) =>
          breadcrumb.path ? (
            <Breadcrumb.Item key={`Breadcrumb.Item-${i}`} linkAs={"div"}>
              {breadcrumb.externally ? (
                <a href={breadcrumb.path}>{breadcrumb.text}</a>
              ) : (
                <Link to={breadcrumb.path}>{breadcrumb.text}</Link>
              )}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item key={"Breadcrumb.Item-active"} active>
              {breadcrumb.text}
            </Breadcrumb.Item>
          )
        )}
      </Breadcrumb>
    </BreadcrumbsWrapper>
  );
};
