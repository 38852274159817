import { MsgValidation, YupHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  IVehicleDataFormMolecule,
  TypeDocumentEnum,
  TypeEntryEnum,
  TypeSignatureEnum,
} from "../../../models";

export const VehicleDataFormValidation = () => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfCnpjValid",
    YupHelper.isCpfCnpjValid
  );

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isEmailValid",
    YupHelper.isEmailValid
  );

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfValid",
    YupHelper.isCpfValid
  );

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCnpjValid",
    YupHelper.isCnpjValid
  );

  const msgRequired = t(MsgValidation.RequiredField);

  return Yup.object<IVehicleDataFormMolecule>({
    securityCodeCrv: Yup.string()
      .required(msgRequired)
      .test(
        "securityCodeCrv",
        t(MsgValidation.InvalidCharactersQuantity),
        (v) => (v?.trim().length === 11 ? true : false)
      ),
    typeDocumentCurrentOwner:
      Yup.number<TypeDocumentEnum>().required(msgRequired),
    documentCurrentOwner: Yup.string()
      .required(msgRequired)
      .when("typeDocumentCurrentOwner", {
        is: (v) => v === TypeDocumentEnum.Cpf,
        then: Yup.string().isCpfValid(t(MsgValidation.Cpf)),
        otherwise: Yup.string().isCnpjValid(t(MsgValidation.Cnpj)),
      }),
    emailCurrentOwner: Yup.string()
      .nullable()
      .required(msgRequired)
      .isEmailValid(t(MsgValidation.Email)),
    valuePurchase: Yup.string().required(msgRequired),
    datePurchase: Yup.date()
      .required(msgRequired)
      .test(
        "invalid",
        t("Invalid Date"),
        (value: Date | null | undefined): boolean => {
          const dateValue = value;
          if (dateValue === undefined || dateValue === null) return false;
          else return true;
        }
      ),
    manufactureYear: Yup.number().required(msgRequired),
    odometerMileage: Yup.string()
      .required(msgRequired)
      .test("invalid", t("Invalid Odometer"), (value: any): boolean => {
        const numValue = parseInt(value);
        return !isNaN(numValue) && numValue > 0;
      }),
    measurementOdometerDate: Yup.date()
      .required(msgRequired)
      .test(
        "invalid",
        t("Invalid Date"),
        (value: Date | null | undefined): boolean => {
          const dateValue = value;
          if (dateValue === undefined || dateValue === null) return false;
          else return true;
        }
      ),
    measurementOdometerHour: Yup.string()
      .required(msgRequired)
      .test("invalid", t("Invalid Hour"), (value: any): boolean => {
        return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
      }),
    stockEntryDate: Yup.date()
      .required(msgRequired)
      .test(
        "invalid",
        t("Invalid Date"),
        (value: Date | null | undefined): boolean => {
          const dateValue = value;
          if (dateValue === undefined || dateValue === null) return false;
          else return true;
        }
      ),
    dealershipEmail: Yup.string().required(msgRequired),
    typeSignature: Yup.number<TypeSignatureEnum>().required(msgRequired),
    stockEntryHour: Yup.string()
      .required(msgRequired)
      .test("invalid", t("Invalid Hour"), (value: any): boolean => {
        return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
      }),
    typeEntryEnum: Yup.number<TypeEntryEnum>().required(msgRequired),
  });
};
