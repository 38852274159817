import { SpinnerCenteredAtom } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { useRenave, useRenaveContext, useSessionContext } from "../../../hooks";
import {
  ConflictRequestError,
  IDealershipRegisterForm,
  IGetAvailableState,
} from "../../../models";
import { HeaderTitleMolecule, RegisterDealershipFormMolecule } from "../../molecules";
import {
  TitleOrganismDiv,
  WrapperRegisterDealershipPage,
} from "./register-dealership.organism.style";
import { Show } from "../../atoms";

export const RegisterDealershipOrganism: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { showGenericErrorModal } = useSessionContext();
  const { userContext } = useRenaveContext();
  const { getCheckDealership, getAvailableState } = useRenave();

  const [initialValues, setInitialValues] = useState<IDealershipRegisterForm>();
  const [needsRegister, setNeedsRegister] = useState(false);
  const [availableStates, setAvailableStates] = useState<IGetAvailableState>();

  const checkRegisterDealership = useCallback(async () => {
    try {
      const data = await getCheckDealership();

      if (
        availableStates?.ufs
          .map((m) => m.toUpperCase())
          .includes(userContext?.dealership?.address?.state.toUpperCase()) &&
        data.isEnabled
      )
        history.push(paths.myStock());
      else history.push(paths.staticRenaveMasterPage());
    } catch (error) {
      if (error instanceof ConflictRequestError) {
        setNeedsRegister(true);
      } else {
        showGenericErrorModal(t("GenericErrorMsg"), null, t("Error"));
      }
    }
  }, [
    availableStates,
    getCheckDealership,
    history,
    showGenericErrorModal,
    t,
    userContext?.dealership?.address?.state,
  ]);

  const getAvailableStateAsync = useCallback(async () => {
    try {
      const data = await getAvailableState();
      setAvailableStates(data);
    } catch (error) {
      showGenericErrorModal(
        t("GenericErrorMsg"),
        () => history.push(paths.staticRenaveMasterPage()),
        t("Error")
      );
    }
  }, [getAvailableState, history, showGenericErrorModal, t]);

  useEffect(() => {
    if (availableStates !== undefined) checkRegisterDealership();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableStates]);

  useEffect(() => {
    getAvailableStateAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (needsRegister && userContext)
      setInitialValues({
        nameUser: userContext.user.name,
        email: userContext.user.email,
        phone: userContext.user.cellPhone,
        updateMyPhone: true,
        confirmData: false,

        dealershipPhone: userContext?.dealership.primaryPhone,
        cnpj: userContext.dealership.cnpj,
        zipCode: userContext?.dealership.address?.zipCode ?? "",
        state: userContext?.dealership.address?.state ?? "",
        neighborhood: userContext?.dealership.address?.neighborhood ?? "",
        number: userContext?.dealership.address?.number ?? "",
        city: userContext?.dealership.address?.city ?? "",
        complement: userContext?.dealership.address?.complement ?? "",
        street: userContext?.dealership.address?.street ?? "",
        legalName: userContext.dealership.legalName,
        name: userContext.dealership.name,
      });
  }, [needsRegister, userContext]);

  return (
      <Show
        when={!!initialValues}
        fallback={
          <div style={{ height: '80vh' }}>
            <SpinnerCenteredAtom />
          </div>
        }>
          <>
            <HeaderTitleMolecule title={t("Renave")} hideReturnButton />
            <Row className="mb-5">
              <TitleOrganismDiv>{t("Register")}</TitleOrganismDiv>
            </Row>
            <WrapperRegisterDealershipPage>
                <RegisterDealershipFormMolecule
                  initialValues={initialValues!}
                  onSuccess={() => history.push(paths.staticRenaveMasterPage())}
                />
            </WrapperRegisterDealershipPage>
          </>
      </Show>
  );
};
