import styled, { css } from "styled-components";
import { ISizeProp } from "../../../models";
import { ButtonSecondary } from "c4u-web-components";

export const BalanceSummaryWrapper = styled.div<{ size?: ISizeProp }>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 182px;
  ${({ size }) =>
    size === "sm"
      ? css`
          min-height: 60px;
        `
      : size === "md"
      ? css`
          min-height: 76px;
        `
      : css`
          min-height: 80px;
        `};
`;

export const LineBalanceHr = styled.hr`
  width: 1px;
  height: 42px;
  margin: 0;
  border: 0;
  border-left: 1px solid #e1e1e2;
  box-sizing: border-box;
`;

export const SummaryWrapper = styled.div`
  background-color: #F9F9F9;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 20px;
  padding: 8px;
  border-radius: 4px;
`

export const BalanceSummaryCard = styled.div<{ size?: ISizeProp }>`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 50px;
  padding: 8px;
  border-radius: 10px;

  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid #E1E1E2;

  @media (max-width: 794px) {
    gap: 30px;
  }

  @media (max-width: 618px) {
    gap: 15px;
    hr {
      height: 40px;
    }
  }
`;

export const BuyCreditsButton = styled(ButtonSecondary)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  padding: 0px;
  width: 167px;
  margin-right: 42px;

  @media (max-width: 767px) {
    width: 45px;
  }

  @media (max-width: 794px) {
    margin-right: 22px;
  }

  @media (max-width: 618px) {
    margin-right: 7px;
  }

  :hover {
    fill: orange;
    svg {
      fill: #FFFFFF;
      g {
        path {
          fill: #FFFFFF;
        }
      }
    }
  }

  svg {
    transition: fill .05s ease-in-out;

    g {
      path {
        transition: fill .05s ease-in-out;
      }
    }
  }
`
