import { Routers } from "../models";
import {
  MyStockPage,
  CheckInVehiclePage,
  AptnessQueryPage,
  CreditStatementPage,
  OrderListPage,
  RegisterDealershipPage,
  StaticRenaveInfoMasterPage,
  HomePage,
  CheckOutVehiclePage,
  CheckInDanfePage,
  CheckOutDanfePage,
  VehicleCheckedOutPage,
  VehicleCheckedInPage,
  CreditAcquisitionPacksPage,
  CheckInAptnessPage,
  CheckInZeroKmPage,
  VehicleCheckedInZeroKmPage,
  VehicleCheckedOutZeroKmPage,
  CheckOutZeroKmPage,
} from "../ui/pages";
import { MainTemplate } from "../ui/templates";

const paths = {
  home: () => `/`,
  myStock: () => `/my-stock`,
  aptnessQuery: () => `/vehicle/aptness-query`,
  checkInAptness: (id: string | number | null = null) =>
    `/vehicle/${!!id ? id : ":id"}/aptness`,
  checkInVehicle: (id: string | number | null = null) =>
    `/vehicle/${!!id ? id : ":id"}/check-in`,
  checkInDanfe: (id: string | number | null = null) =>
    `/vehicle/${!!id ? id : ":id"}/check-in-danfe`,
  vehicleCheckedIn: (id: string | number | null = null) =>
    `/vehicle/${!!id ? id : ":id"}/checked-in`,
  checkOutVehicle: (id: string | number | null = null) =>
    `/vehicle/${!!id ? id : ":id"}/check-out`,
  checkOutDanfe: (id: string | number | null = null) =>
    `/vehicle/${!!id ? id : ":id"}/check-out-danfe`,
  vehicleCheckedOut: (id: string | number | null = null) =>
    `/vehicle/${!!id ? id : ":id"}/checked-out`,
  registerDealership: () => `/register`,
  creditStatement: () => `/credit-statement`,
  orderList: () => "/order-list",
  CreditAcquisitionPacksPage: () => `/credit-acquisition-packs`,
  staticRenaveMasterPage: () => `/wait-contact`,
  checkInZeroKm: (id: string | number | null = null) =>
    `/vehicle/${!!id ? id : ":id"}/check-in-zero-km`,
  vehicleCheckedInZeroKm: (id: string | number | null = null) =>
    `/vehicle/${!!id ? id : ":id"}/checked-in-zero-km`,
  checkOutZeroKm: (id: string | number | null = null) =>
    `/vehicle/${!!id ? id : ":id"}/check-out-zero-km`,
  vehicleCheckedOutZeroKm: (id: string | number | null = null) =>
    `/vehicle/${!!id ? id : ":id"}/checked-out-zero-km`,
};

const routes = [
  {
    path: paths.home(),
    component: HomePage,
    template: MainTemplate,
    title: "Home",
  },
  {
    path: paths.registerDealership(),
    component: RegisterDealershipPage,
    template: MainTemplate,
    title: "Register Dealership",
  },
  {
    path: paths.myStock(),
    component: MyStockPage,
    template: MainTemplate,
    title: "My Stock",
  },
  {
    path: paths.creditStatement(),
    component: CreditStatementPage,
    template: MainTemplate,
    title: "Credit Statement",
  },
  {
    path: paths.orderList(),
    component: OrderListPage,
    template: MainTemplate,
    title: "Order List",
  },
  {
    path: paths.CreditAcquisitionPacksPage(),
    component: CreditAcquisitionPacksPage,
    template: MainTemplate,
    title: "Credit Acquisition Packs",
  },

  {
    path: paths.aptnessQuery(),
    component: AptnessQueryPage,
    template: MainTemplate,
    title: "Aptness Query",
  },
  {
    path: paths.checkInAptness(),
    component: CheckInAptnessPage,
    template: MainTemplate,
    title: "Check-in Vehicle Aptness",
  },
  {
    path: paths.checkInVehicle(),
    component: CheckInVehiclePage,
    template: MainTemplate,
    title: "Check-in Vehicle",
  },
  {
    path: paths.checkInDanfe(),
    component: CheckInDanfePage,
    template: MainTemplate,
    title: "Check-in Danfe",
  },
  {
    path: paths.vehicleCheckedIn(),
    component: VehicleCheckedInPage,
    template: MainTemplate,
    title: "Checked-in Vehicle",
  },
  {
    path: paths.checkOutVehicle(),
    component: CheckOutVehiclePage,
    template: MainTemplate,
    title: "Check-out Vehicle",
  },
  {
    path: paths.checkOutDanfe(),
    component: CheckOutDanfePage,
    template: MainTemplate,
    title: "Check-out Danfe",
  },
  {
    path: paths.vehicleCheckedOut(),
    component: VehicleCheckedOutPage,
    template: MainTemplate,
    title: "Check-out Vehicle",
  },
  {
    path: paths.staticRenaveMasterPage(),
    component: StaticRenaveInfoMasterPage,
    template: MainTemplate,
    title: "Renave Info",
  },
  {
    path: paths.checkInZeroKm(),
    component: CheckInZeroKmPage,
    template: MainTemplate,
    title: "Check-in Zero km",
  },
  {
    path: paths.vehicleCheckedInZeroKm(),
    component: VehicleCheckedInZeroKmPage,
    template: MainTemplate,
    title: "Checked-in Zero km",
  },
  {
    path: paths.checkOutZeroKm(),
    component: CheckOutZeroKmPage,
    template: MainTemplate,
    title: "Check-out Zero km",
  },
  {
    path: paths.vehicleCheckedOutZeroKm(),
    component: VehicleCheckedOutZeroKmPage,
    template: MainTemplate,
    title: "Checked-out Zero km",
  },
] as Routers[];

export { paths, routes as default };
