import {
  ButtonPrimary,
  ButtonSecondary,
  FormikControlAtom,
  MaskHelper,
  MsgValidation,
  YupHelper,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { EnumHelper } from "../../../../helper";
import { useSessionContext } from "../../../../hooks";
import {
  ConflictRequestError,
  IPostReturnZeroKmRequest,
  PostReturnZeroKmRequest,
  ReturnZeroKmReasonsEnum,
} from "../../../../models";
import { FormikControlCalendarAtom } from "../../../atoms";
import { FeedbackModal } from "../../feedback-modal/feedback-modal.molecule";
import {
  ReturnFormButtonsGroup,
  ReturnModalFormRow,
} from "./return-to-maker-modal.molecule.style";

interface IProps {
  id: number | undefined;
  setId: (v: number | undefined) => void;
  returnEntryCallback: (r: PostReturnZeroKmRequest) => Promise<void>;
  onReturnSuccessful: () => void;
  entryDanfeNumber: string;
}

export const ReturnToMakerModalMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { handleFormikException, showGenericErrorModal } = useSessionContext();

  const msgRequired = t(MsgValidation.RequiredField);

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isValidByRegex",
    YupHelper.isValidByRegex
  );

  const validations = Yup.object<IPostReturnZeroKmRequest>({
    id: Yup.number().required(msgRequired),
    returnDate: Yup.date().required(msgRequired),
    invoiceKey: Yup.string()
      .required(msgRequired)
      .test(
        "invalid return danfe",
        t("Return Danfe can't be equal to entry Danfe"),
        (value): boolean => {
          if (!value) return true;
          if (value.length === 44 && value === props.entryDanfeNumber)
            return false;
          return true;
        }
      )
      .isValidByRegex(/\d{44}/, t("Invalid Danfe length")),
    returnZeroKmReason:
      Yup.number<ReturnZeroKmReasonsEnum>().required(msgRequired),
  });

  const formik = useFormik<IPostReturnZeroKmRequest>({
    initialValues: { id: props.id } as IPostReturnZeroKmRequest,
    onSubmit: async (values, { setErrors }) => {
      try {
        await props.returnEntryCallback(new PostReturnZeroKmRequest(values));
        props.onReturnSuccessful();
      } catch (e: any) {
        if (e instanceof ConflictRequestError) {
          handleFormikException(e, setErrors);
        } else {
          showGenericErrorModal(t("GenericErrorMsg"), onHide, t("Error"));
        }
      } finally {
        onHide();
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const onHide = () => {
    props.setId(undefined);
    formik.resetForm();
  };

  useEffect(() => {
    if (props.id) formik.setFieldValue("id", props.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  return (
    <FeedbackModal
      show={props.id !== undefined}
      message={t("Confirm return message")}
      titleHeader={t("Return 0km vehicle")}
      onHide={onHide}
      typeIcon="none"
      size="md"
      hideFooter
      reducePaddingTop
    >
      <Form onSubmit={formik.handleSubmit}>
        <ReturnModalFormRow className="mt-3">
          <FormikControlCalendarAtom
            label={t("Return date")}
            property="returnDate"
            formik={formik}
            xs={12}
          />
        </ReturnModalFormRow>
        <ReturnModalFormRow>
          <FormikControlAtom
            label={t("Return InvoiceKey")}
            property={"invoiceKey"}
            translate={t}
            formik={formik}
            type="only-numbers"
            maxLength={MaskHelper.DANFE_NUMBER_LENGTH}
            placeholderChar={"\u2000"}
          />
        </ReturnModalFormRow>
        <ReturnModalFormRow>
          <FormikControlAtom
            translate={t}
            type="select"
            data={EnumHelper.getComboFromEnum(ReturnZeroKmReasonsEnum, t)}
            formik={formik}
            property="returnZeroKmReason"
            label={t("Return reason")}
          />
        </ReturnModalFormRow>
        <Form.Row>
          <ReturnFormButtonsGroup>
            <ButtonSecondary onClick={onHide}>{t("Cancel")}</ButtonSecondary>
            <ButtonPrimary type="submit" loading={formik.isSubmitting}>
              {t("Confirm")}
            </ButtonPrimary>
          </ReturnFormButtonsGroup>
        </Form.Row>
      </Form>
    </FeedbackModal>
  );
};
