import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { HeaderTitleMolecule } from "../../molecules";
import { CreditAcquisitionTextTitle } from "../../molecules/credit-acquisition-form/credit-acquisition-form.molecule.style";
import {
  CreditAcquisitionPacksOrganism,
  CreditAcquisitionPaymentOrganism,
} from "../../organisms";
import { GradientHR } from "../shared/shared.page.style";

export const CreditAcquisitionPacksPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [displaysPaymentForms, setDisplaysPaymentForms] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);

  return (
    <>
      <HeaderTitleMolecule
        title={t("CreditAcquisition")}
        returnButtonActionOverride={() => history.push(paths.myStock())}
        haveButtonsCreditStatementAndOrderList={true}
      />

      <GradientHR />

      <CreditAcquisitionPacksOrganism
        displaysPaymentForms={displaysPaymentForms}
        setDisplaysPaymentForms={setDisplaysPaymentForms}
        selectedCard={selectedCardId}
        setSelectedCard={setSelectedCardId}
      />

      <Row>
        <Col>
          <CreditAcquisitionTextTitle>
            {t("Payment")}
          </CreditAcquisitionTextTitle>
          <hr />
        </Col>
      </Row>

      {displaysPaymentForms && selectedCardId !== null && (
        <CreditAcquisitionPaymentOrganism packId={selectedCardId} />
      )}
    </>
  );
};
