import styled from "styled-components";

export const ManageVehicleContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;

  input,
  select {
    background: ${(props) => props.theme.colors.COXWhite};
  }

  .adjust-bottom-gap {
    translate: -3px 15px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 24px;

  button {
    margin: unset;
  }

  .line-spacing-115 {
    line-height: 115%;
  }

  margin-top: 24px;
  &.no-top-margin {
    margin-top: 0;
  }
`;
