import { ButtonSecondary, theme } from "c4u-web-components";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { paths } from "../../../../constants";
import { IBreadcrumbs } from "../../../../models";
import { BreadcrumbsAtom, CreditOrderNavigationAtom } from "../../../atoms";
import {
  AllCapsButtonTertiary,
  BoxButtonsCreditStatementAndOrderList,
  HeaderTitleWrapper,
  TitleDiv,
} from "./header-title.molecule.style";

interface IProps {
  title: string;
  navigationButtonText?: string;
  navigationButtonAction?: () => void;
  returnButtonActionOverride?: () => void;
  disableReturnButton?: boolean;
  hideReturnButton?: boolean;
  haveButtonsCreditStatementAndOrderList?: boolean;
}

export const HeaderTitleMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const breadcrumbs: IBreadcrumbs[] = [
    { text: t("Home"), path: process.env.REACT_APP_BASE_URL, externally: true },
    { text: t("Renave"), path: paths.myStock() },
    { text: props.title },
  ];

  const hideReturnButton = props.hideReturnButton || props.disableReturnButton;

  return (
    <HeaderTitleWrapper>
      <BreadcrumbsAtom
        breadcrumbs={breadcrumbs}
        backgroundColor={theme.colors.Background}
      />
      <Row>
        {!hideReturnButton && (
          <Col xs={"auto"} className="center-align-vertical">
            <ButtonSecondary
              sizex={"sm"}
              sizey={"thin"}
              onClick={
                props.returnButtonActionOverride ?? (() => history.go(-1))
              }
              disabled={!!props.disableReturnButton}
            >
              {t("Back")}
            </ButtonSecondary>
          </Col>
        )}

        <Col xs className={"my-3 my-md-0"}>
          <TitleDiv>{props.title}</TitleDiv>
        </Col>
        <Col xs={"auto"} className="text-right center-align-vertical ml-auto">
          {props.navigationButtonText && props.navigationButtonAction && (
            <AllCapsButtonTertiary
              sizex={"md"}
              sizey={"thin"}
              onClick={props.navigationButtonAction}
            >
              {props.navigationButtonText}
            </AllCapsButtonTertiary>
          )}
        </Col>
        {props.haveButtonsCreditStatementAndOrderList && (
          <BoxButtonsCreditStatementAndOrderList>
            <CreditOrderNavigationAtom />
          </BoxButtonsCreditStatementAndOrderList>
        )}
      </Row>
    </HeaderTitleWrapper>
  );
};
