import { CoxIcon } from "c4u-web-components";
import React from "react";
import { paths } from "../../../constants";
import { useTranslation } from "react-i18next";
import { CreditAndOrdersWrapper, Divider } from "./credit-order-navigation.atom.style";
import { Link } from "react-router-dom";

export const CreditOrderNavigationAtom: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CreditAndOrdersWrapper>
      <Link to={paths.creditStatement()}>
        <CoxIcon name="registrations" fillColor="#0585C8" width={16} height={16}/>
        {t("CreditStatement")}
      </Link>
      <Divider />
      <Link to={paths.orderList()}>
        <CoxIcon name="book" viewBox='0 0 16 12'/>
        {t("OrderList")}
      </Link>
    </CreditAndOrdersWrapper>
  );
};
