import { MsgValidation, YupHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ICheckInZeroKmModel } from "../../../models";

export const ZeroKmVehicleDataFormValidation = () => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isValidByRegex",
    YupHelper.isValidByRegex
  );

  const msgRequired = t(MsgValidation.RequiredField);

  return Yup.object<ICheckInZeroKmModel>({
    id: Yup.number().required(msgRequired),
    kbbId: Yup.number().required(msgRequired),
    vin: Yup.string().required(msgRequired),

    brand: Yup.string().required(msgRequired),
    model: Yup.string().required(msgRequired),
    version: Yup.string().required(msgRequired),
    modelYear: Yup.number().required(msgRequired),

    manufactureYear: Yup.number().required(msgRequired),
    valuePurchase: Yup.string().required(msgRequired),
    entryType: Yup.string(),
    stockEntryDate: Yup.date()
      .required(msgRequired)
      .test(
        "invalid",
        t("Invalid Date"),
        (value: Date | null | undefined): boolean => {
          const dateValue = value;
          if (dateValue === undefined || dateValue === null) return false;
          else return true;
        }
      ),
    odometerMileage: Yup.string().nullable().required(msgRequired),
    measurementOdometerDate: Yup.date()
      .required(msgRequired)
      .test(
        "invalid",
        t("Invalid Date"),
        (value: Date | null | undefined): boolean => {
          const dateValue = value;
          if (dateValue === undefined || dateValue === null) return false;
          else return true;
        }
      ),
    measurementOdometerHour: Yup.string()
      .required(msgRequired)
      .isValidByRegex(
        /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
        t("Invalid Hour")
      ),
    invoiceKey: Yup.string()
      .required(msgRequired)
      .isValidByRegex(/\d{44}/, t("Invalid Danfe length")),
  });
};
