import { ButtonTertiary } from "c4u-web-components";
import { Form } from "react-bootstrap";
import styled from "styled-components";

interface IProps {
  selectButton?: boolean;
}

export const CreditAcquisitionStyledForm = styled(Form)`
  margin: 40px 0 0 0;
  label {
    font-family: ${(props) => props.theme.fontFamily.OpenSans};
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;

    color: ${(props) => props.theme.colors.Dark};
  }
`;

export const CreditAcquisitionTextTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  color: ${(props) => props.theme.colors.BlueNavy};
`;

export const BoxTheButtonPayment = styled.div`
  display: flex;
  gap: 24px;
  margin: 15px 0 20px 0;
`;

export const CreditPurchaseOptionPayment = styled.button<IProps>`
  width: 170px;
  height: 42px;
  background: ${(color) => (color.selectButton ? "#3a5693" : "#fff")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: ${(color) => (color.selectButton ? "#fff" : "#3a5693")};
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  border: ${(color) => (color.selectButton ? "none" : "1px solid #3A5693")};

  g {
    path {
      fill: ${(color) => (color.selectButton ? "#fff" : "#3a5693")};
    }
  }
`;

export const BoxTheCreditCard = styled.div`
  margin: 0 0 20px 0;
  span {
    color: ${({ theme }) => theme.colors.BlueNavy};
    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const BoxTheBankSplip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  span {
    color: ${({ theme }) => theme.colors.BlueNavy};
    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const ButtonBankSplipGeneration = styled(ButtonTertiary)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  height: 40px;
  width: 264px;
  padding: 0px 24px;
  margin: unset;

  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  text-transform: uppercase;

  @media (max-width: 991px) {
    width: 70px;
  }
`;
