import styled from "styled-components";

export const WrapperCreditAcquisition = styled.div`
  button {
    margin: 0 !important;
  }
`;

export const BoxPayNow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
