import { FormikControlAtom, MaskHelper } from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { EnumHelper } from "../../../helper";
import { TypeCrvEnum } from "../../../models";
import { IGetRestrictionRequest } from "../../../models/apis/renave/get-restriction.request";
import { VehicleAptnessValidationSchema } from "./vehicle-aptness-form.validation";

interface IProps {
  initialValues: IGetRestrictionRequest;
  setIsSubmitting?: (v: boolean) => void;
  setIsPlateValid?: (v: boolean) => void;
  goSubmit?: boolean;
  onSubmit?: (values: any, errors: any) => Promise<void>;
  disableAll: boolean;
}

export const VehicleAptnessFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = useFormik<IGetRestrictionRequest>({
    initialValues: props.initialValues ?? ({} as IGetRestrictionRequest),
    onSubmit: async (values, { setErrors }) => {
      if (props.onSubmit) await props.onSubmit(values, { setErrors });
    },
    validationSchema: VehicleAptnessValidationSchema(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  const plateValid = useMemo(() => {
    return formik.values.plate.replace("-", "").trim().length === 7;
  }, [formik.values.plate]);

  const crvTypeCombo = useMemo(
    () => EnumHelper.getComboFromEnum(TypeCrvEnum, t),
    [t]
  );

  useEffect(() => {
    if (props.setIsPlateValid) props.setIsPlateValid(plateValid);
    // eslint-disable-next-line
  }, [plateValid]);

  useEffect(() => {
    if (props.goSubmit) formik.submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.goSubmit]);

  useEffect(() => {
    if (props.setIsSubmitting) props.setIsSubmitting(formik.isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isSubmitting]);

  useEffect(() => {
    if (!plateValid) {
      formik.setFieldValue("renavam", "");
      formik.setFieldValue("numberCrv", "");
      formik.setFieldValue("typeCrv", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plateValid]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Row>
        <FormikControlAtom
          translate={t}
          mask={MaskHelper.PlateBr}
          formik={formik}
          property={"plate"}
          label={t("Plate")}
          md={6}
          func={(v: string) => v.toUpperCase()}
          placeholderChar={"\u2000"}
          disabled={props.disableAll}
        />
        {plateValid && (
          <>
            <FormikControlAtom
              label={t("Renavam")}
              formik={formik}
              property={"renavam"}
              translate={t}
              type="only-numbers"
              maxLength={MaskHelper.RENAVAM_LENGTH}
              placeholderChar={" "}
              md={4}
              disabled={props.disableAll}
            />
          </>
        )}
      </Form.Row>

      {plateValid && (
        <Form.Row>
          <FormikControlAtom
            label={t("NumberCrv")}
            formik={formik}
            property={"numberCrv"}
            translate={t}
            type="only-numbers"
            maxLength={MaskHelper.CRV_LENGTH}
            placeholderChar={"\u2000"}
            md={4}
            disabled={props.disableAll}
          />
          <FormikControlAtom
            type="select"
            md={6}
            data={crvTypeCombo}
            formik={formik}
            property={"typeCrv"}
            label={t("TypeCrv")}
            translate={t}
            disabled={props.disableAll}
          />
        </Form.Row>
      )}
    </Form>
  );
};
