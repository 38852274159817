import type React from "react";

type ShowProps = {
  when: boolean;
  fallback: React.ReactElement | null;
  children?: React.ReactElement | null;
};

export const Show: React.FC<ShowProps> = ({ when, fallback, children }) => {
  if (!when) {
    return fallback;
  }

  return children || null;
};
