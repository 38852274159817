import { CoxIcon, SpinnerCenteredAtom } from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IGetDailyBalance, ISizeProp } from "../../../models";
import { BalanceSummaryAtom } from "../../atoms";
import {
  BalanceSummaryCard,
  BalanceSummaryWrapper,
  BuyCreditsButton,
  LineBalanceHr,
  SummaryWrapper,
} from "./balance-summary.molecule.style";
import { paths } from "../../../constants";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

interface IProps {
  goUpdate: boolean;
  getBalanceCallback: () => Promise<IGetDailyBalance>;
  setDailyBalance?: (v: number | null) => void;
  size?: ISizeProp;
}

export const BalanceSummaryMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const [zeroKmQuantity, setZeroKmQuantity] = useState<number>();
  const [usedQuantity, setUsedQuantity] = useState<number>();
  const [detranQuantity, setDetranQuantity] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);

  const isSmallScreen = useMediaQuery({ maxWidth: 767 });

  const handleUpdateBalance = useCallback(() => {
    setIsLoading(true);
    props
      .getBalanceCallback()
      .then((data) => {
        if (props.setDailyBalance) props.setDailyBalance(data.dailyBalance);
        setZeroKmQuantity(data.availableZeroKmQuantity);
        setUsedQuantity(data.availableUsedQuantity);
        setDetranQuantity(data.availableDetranQuantity);
      })
      .catch((e) => {
        console.log("Error updating balance", e);
        if (props.setDailyBalance) props.setDailyBalance(null);
        setZeroKmQuantity(undefined);
        setUsedQuantity(undefined);
        setDetranQuantity(undefined);
      })
      .finally(() => setIsLoading(false));
  }, [props]);

  useEffect(() => {
    handleUpdateBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.goUpdate) handleUpdateBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.goUpdate]);

  // const hasZeroKmQuantity = useMemo(
  //   () => zeroKmQuantity !== null && zeroKmQuantity !== undefined,
  //   [zeroKmQuantity]
  // );
  const hasUsedQuantity = useMemo(
    () => usedQuantity !== null && usedQuantity !== undefined,
    [usedQuantity]
  );
  const hasDetranQuantity = useMemo(
    () => detranQuantity !== null && detranQuantity !== undefined,
    [detranQuantity]
  );

  return (
    <BalanceSummaryWrapper size={props.size}>
      {isLoading ? (
        <SpinnerCenteredAtom />
      ) : (
        <BalanceSummaryCard size={props.size}>
          <SummaryWrapper>
            <BalanceSummaryAtom
              label={t("Balance ZeroKm")}
              value={zeroKmQuantity}
              size={props.size}
            />
            <LineBalanceHr />
            <BalanceSummaryAtom
              label={t("Balance Used")}
              value={usedQuantity}
              size={props.size}
            />
            {hasUsedQuantity && hasDetranQuantity && <LineBalanceHr />}
            {hasDetranQuantity && (
              <BalanceSummaryAtom
                label={t("Balance Detran")}
                value={detranQuantity}
                size={props.size}
              />
            )}
          </SummaryWrapper>
          <BuyCreditsButton
            onClick={() => push(paths.CreditAcquisitionPacksPage())}
            sizey="thin"
          >
            <CoxIcon name="dolar-circle-v2" width={14} height={14}/>
            {!isSmallScreen && t("BuyCredits")}
          </BuyCreditsButton>
        </BalanceSummaryCard>
      )}
    </BalanceSummaryWrapper>
  );
};
