import styled from "styled-components";

export const CreditPacksSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 12px;
  margin: 30px 0 0 0;
`;

export const CreditPacksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  gap: 24px;
`;
