import styled from "styled-components";
interface IProps {
  selectButton?: boolean;
  hasSelectedCreditPurchase?: boolean;
}

export const WrapperCardDiv = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
  margin: 0 0 40px 0;
`;

export const BoxTheButton = styled.div`
  display: flex;
  gap: 24px;
  margin: 40px 0 0 0;
`;

export const CreditPurchaseOption = styled.button<IProps>`
  width: 168px;
  height: 42px;
  background: ${(color) => (color.selectButton ? "#3a5693" : "#fff")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: ${(color) => (color.selectButton ? "#fff" : "#3a5693")};
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  border: ${(color) => (color.selectButton ? "none" : "1px solid #3A5693")};
`;
